body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  min-width: 0 !important;
  min-height: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#zmmtg-root {
  display: none;
}
.schedule-meeting-date input[type=text] {
  outline: none !important;
}

.schedule-meeting-date input[type=text]:focus {
  outline: none !important;
}

/* @media (min-width: 850px) {
  .footer__leave-btn-container::before {
    content: "";
    background-image: url('./assets/images/avod.png') !important;
    background-size: 100% auto;
    height: inherit;
    width: 100px;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    right: 115px;
  }
}

@media (max-width: 850px) {
  div#wc-container-left::before {
    content: "";
    background-image: url('./assets/images/avod.png') !important;
    background-size: contain;
    width: 58px;
    background-repeat: no-repeat;
    background-position: center top;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    height: inherit;
    z-index: 100;
    margin: 0 auto;
  }
} */

#meeting-info-indication {
  order: 1;
}

.meeting-info-container--left-side > .video-opt-buttons-container {
  order: 3;
  margin-left: auto;
}

.meeting-info-container--left-side > .video-opt-buttons-container > button {
  background: #0171eb;
  color: white;
}

#app-signal {
  order: 2;
}

input[type=text]:focus {
  outline: none !important;
}

.zoom-workplace-logo {
  display: none;
}

.meeting-header-logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
}

.meeting-header-avod-logo {
  content: url('./assets/images/avod.png');
  max-height: 25px;
}

.meeting-footer-avod-logo {
  content: url('./assets/images/avod.png');
  max-height: 25px;
  display: none;
}

.meeting-header-hitsmid-logo {
  content: url('./assets/images/hitsmid.png');
  max-height: 25px;
}

.meeting-header-avod-text {
  color: #D9D9D9;
  text-align: center;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: normal;
}

.meeting-header-avod-container {
  display: flex;
  gap: 6px;
  align-items: flex-end;
  user-select: none;
}

.page-footer {
  display: none;
}

.wr-main-content {
  margin: 0 !important;
  border-radius: 0 !important;
}

.wr-information {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  background-color: white;
  border-top-left-radius: 16px;
  padding: 24px !important;
}

.mobile-waiting-room {
  padding: 0;
  position: relative;
}

.mobile-waiting-room .wr-header-title {
  display: none;
}

.meeting-info-icon__icon-wrap {
  display: none  !important;
}

@media (max-width: 768px) {
  .meeting-header-avod-container {
    display: none;
  }
}

/* @media (max-width: 768px) {
  .meeting-header {
    margin: 0 12px;
  }

  .meeting-header-avod-logo {
    height: 18px;
    width: auto;
  }

  .meeting-header-hitsmid-logo {
    width: 82px;
    height: auto;
  }

  .meeting-header-avod-text {
    font-size: 10px;
  }
  
  .meeting-header-avod-container {
    gap: 6px;
  }
} */